<template>
    <div>
        <fromSearch :object_='hotWallet_' @searchFun='getList' />
        <avue-crud  :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange" @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="viewOpen('add')">{{$t('button.add')}} </el-button>
            </template>  
            <template #status='scope'>
                <el-switch v-model="scope.row.status" :inactive-value='0' :active-value='1' @change='changeStatus(scope.row)'/>
            </template>   
            <template #caozuo='scope'>
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="viewOpen('update',scope.row)">{{$t('button.update')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
        <addView ref='addView_' @successFun='successFun'/>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import fromSearch from '@/components/fromSearch.vue'
    import { ref,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/cryptocurrency/hotWallet";
    import { object } from "@/const/from/cryptocurrency/walletSettings/hotWallet";

    import addView from './add.vue' 
    import { ElNotification } from 'element-plus'
    const { $t } = getCurrentInstance().proxy;
    import { turnTimeFun } from '@/utils/util.js'
    
    const addView_ = ref(null)
    const tableOption = ref({});tableOption.value = tableOption1()
    const roleManager_btn_edit = ref(true)
    const roleManager_btn_add = ref(true)
    const hotWallet_ = ref(object)

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getAssetswalletinfoList'],{walletType:1,protocolType:1})
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const changeStatus = (value_)=>{
        let obj_ = {
            walletInfoId: value_.walletInfoId,
            status: value_.status
        }
        if(value_.walletId){
            Api_.updateAssetswalletinfoStatusView(obj_).then(res=>{
                if(res.code ===0 ){
                    getList(1)
                    ElNotification({
                        message: value_.status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const successFun = () => {
        page.currentPage = 1;
        getList(1)
    }
    const viewOpen = (str,scope)=>{
        addView_.value.setData(scope,str)
    }
    getList(1)
</script>

